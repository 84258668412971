import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import LinearProgress from "@material-ui/core/LinearProgress"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { navigate } from "gatsby"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import passwordRecoveryGif from "src/images/mainPic/icon_letter.gif"
import hitRateIcon from "src/images/mainPic/signInIcon.png"

import Theme from "../entity/theme/theme"
import PasswordStepper from "../presentation/organisms/passwordRecovery/passwordStepper"
import { passwordRepository } from "../repository/action"

const useStyles = makeStyles({
    root: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: Theme.font.fontFamily,
        borderRadius: "8px",
        border: "1px solid #dadce0",
        maxWidth: "100%",
        position: "absolute",
        height: "560px",
        left: "50%",
        top: "50%",
        marginLeft: "-50%",
        marginTop: "-280px",
        marginBottom: "10px",
    },
    media: {
        height: 140,
    },
    title: {
        display: "inline-flex",
        position: "absolute",
        top: "0px",
        left: "0px",
    },
    hitRateTitle: {
        color: "#3f51b5",
        margin: "5px auto",
        fontSize: "24px",
        alignItems: "center",
    },
    imgStyle: {
        width: "40px",
        height: "40px",
        maxWidth: "215px",
        margin: "3px auto 0px auto",
        alignItems: "center",
    },
    mainBoxTitle: {
        margin: "30px auto",
    },
    formWidth: {
        width: "75%",
    },
})

export default function PasswordRecovery() {
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)
    const [activeDetailsStep, setActiveDetailsStep] = React.useState(0)
    const [linearProgressAnimation, setLinearProgressAnimation] = React.useState(false)
    const [registerData, setRegisterData] = React.useState({ email: "" })
    const [confirmCode, setConfirmCode] = React.useState(0)
    const [userData, setUserData] = React.useState({})
    const [errorFlag, setErrorFlag] = React.useState(false)
    const [confirmCount, setConfirmCount] = React.useState(0)
    const [buttonFlag, setButtonFlag] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState("")

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    })

    const registerDataChange = (name) => (event) => {
        setRegisterData({ ...registerData, [name]: event.target.value })
    }

    const checkPassword = (targetData) => {
        let arrayTargetData = [...targetData]
        let flag = arrayTargetData.some((value) => {
            return !(
                value.match(/[a-z 0-9]/i) == null ||
                targetData.search(/[0-9]/) == -1 ||
                targetData.search(/[a-z]/) == -1
            )
        })
        ////////console.log(flag);
        return flag
    }

    const handleNext = () => {
        switch (activeDetailsStep) {
            case 0: //メールアドレス入力
                setButtonFlag(true)
                setActiveDetailsStep((prevActiveDetaiilsStep) => prevActiveDetaiilsStep + 1)
                break
            case 1: //ユーザー名入力or確認コード入力
                setButtonFlag(true)
                if (confirmCode > 0) {
                    setLinearProgressAnimation(true)
                    if (registerData.confirmCode == confirmCode) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                        setActiveDetailsStep((prevActiveDetaiilsStep) => prevActiveDetaiilsStep + 2)
                        setLinearProgressAnimation(false)
                    } else {
                        setButtonFlag(false)
                        setErrorFlag(true)
                        setLinearProgressAnimation(false)
                        setConfirmCount((confirmCount) => confirmCount + 1)
                        if (confirmCount == 3) {
                            setActiveDetailsStep(
                                (prevActiveDetaiilsStep) => prevActiveDetaiilsStep + 4
                            )
                        }
                    }
                } else {
                    setLinearProgressAnimation(true)
                    passwordRepository
                        .userCheck(registerData)
                        .then((userData) => {
                            setActiveDetailsStep(
                                (prevActiveDetaiilsStep) => prevActiveDetaiilsStep + 1
                            )
                            setLinearProgressAnimation(false)
                            setUserData(userData)
                            if (Object.keys(userData).length > 1) {
                                setActiveStep((prevActiveStep) => prevActiveStep + 1)
                            }
                        })
                        .catch(() => {
                            setActiveDetailsStep(5)
                        })
                }
                break
            case 2: //メール送信
                setButtonFlag(true)
                setLinearProgressAnimation(true)
                if (Object.keys(userData).length > 1) {
                    passwordRepository
                        .confirmCodeSend(registerData)
                        .then((code) => {
                            setConfirmCode(Number(code))
                            setActiveDetailsStep(
                                (prevActiveDetaiilsStep) => prevActiveDetaiilsStep - 1
                            )
                            setLinearProgressAnimation(false)
                        })
                        .catch(() => {
                            setActiveDetailsStep(5)
                        })
                } else {
                    setUserData({})
                }
                break
            case 3: //パスワードの設定
                setButtonFlag(true)
                if (registerData.password === registerData.confirmPassword) {
                    if (checkPassword(registerData.password)) {
                        if (registerData.password.length > 7) {
                            setLinearProgressAnimation(true)
                            passwordRepository
                                .passwordChange(registerData, userData)
                                .then(() => {
                                    setLinearProgressAnimation(false)
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                                    setActiveDetailsStep(
                                        (prevActiveDetaiilsStep) => prevActiveDetaiilsStep + 1
                                    )
                                })
                                .catch(() => {
                                    setActiveDetailsStep(5)
                                })
                        } else {
                            setErrorFlag(true)
                            setPasswordError("8文字以上で入力してください")
                            setButtonFlag(false)
                        }
                    } else {
                        setErrorFlag(true)
                        setPasswordError("半角英数字を組み合わせて入力してください")
                        setButtonFlag(false)
                    }
                } else {
                    setErrorFlag(true)
                    setPasswordError("パスワードが確認と異なります")
                    setButtonFlag(false)
                }
                break
            case 4: //パスワード変更変更完了
                navigate("/")
                break
            case 5: //確認コードerror
                window.location.reload() //リロード
                break
            default:
                break
        }
    }

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
        setActiveDetailsStep((prevActiveDetaiilsStep) => prevActiveDetaiilsStep - 1)
        setUserData({})
    }

    const buttonFlagReset = () => {
        setButtonFlag(false)
        setErrorFlag(false)
    }
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <>
            <Card className={classes.root}>
                {linearProgressAnimation && <LinearProgress />}
                <CardContent>
                    <div className={classes.title}>
                        <img src={hitRateIcon} className={classes.imgStyle} />
                        <Typography className={classes.hitRateTitle}>HitRate</Typography>
                    </div>
                    <Typography style={{ margin: "30px auto 30px auto", fontSize: "24px" }}>
                        HitRateアカウントの復旧
                    </Typography>
                    <PasswordStepper
                        activeStep={activeStep}
                        activeDetailsStep={activeDetailsStep}
                        setActiveStep={setActiveStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        userData={userData}
                        confirmCode={confirmCode}
                        buttonFlag={buttonFlag}
                    >
                        <div>
                            <SwipeableViews
                                index={activeDetailsStep}
                                disabled
                                onTransitionEnd={buttonFlagReset}
                            >
                                <div style={Object.assign({})}>
                                    <div className={classes.mainBoxTitle}>
                                        <Typography variant="h6">メールアドレスを探す</Typography>
                                        <Typography variant="h7">
                                            登録されたメールアドレスを入力してください
                                        </Typography>
                                    </div>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        name="email"
                                        label="メールアドレス"
                                        type="email"
                                        inputProps={{ style: { padding: "13px 15px" } }}
                                        className={classes.formWidth}
                                        onChange={registerDataChange("email")}
                                        autoComplete="current-password"
                                    />
                                </div>
                                <div style={Object.assign({})}>
                                    {confirmCode > 99999 ? (
                                        <div>
                                            <div className={classes.mainBoxTitle}>
                                                <Typography variant="h6">コードの入力</Typography>
                                                <Typography variant="h7">
                                                    確認コードを
                                                    <span style={{ maxWidth: "30px" }}>
                                                        {registerData.email}
                                                    </span>
                                                    <br />
                                                    に送信しました
                                                </Typography>
                                            </div>
                                            <FormControl error={errorFlag}>
                                                <TextField
                                                    error={errorFlag}
                                                    variant="outlined"
                                                    margin="normal"
                                                    label="コードを入力"
                                                    name="confirmCode"
                                                    inputProps={{ style: { padding: "13px 15px" } }}
                                                    style={{ width: "100%", margin: "auto" }}
                                                    id="confirmCode"
                                                    onChange={registerDataChange("confirmCode")}
                                                />
                                                {errorFlag && (
                                                    <FormHelperText id="component-error-text">
                                                        コードが間違っています。もう一度お試しください。
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "15px" }}>
                                            <div>
                                                <Typography variant="h6">名前を入力する</Typography>
                                                <Typography variant="h7">
                                                    HitRate アカウントの名前を入力してください
                                                </Typography>
                                            </div>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                label="性"
                                                name="lastName"
                                                inputProps={{ style: { padding: "13px 15px" } }}
                                                //autoComplete="lastName"
                                                className={classes.formWidth}
                                                onChange={registerDataChange("lastName")}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                label="名"
                                                name="firstName"
                                                inputProps={{ style: { padding: "13px 15px" } }}
                                                //autoComplete="firstName"
                                                className={classes.formWidth}
                                                onChange={registerDataChange("firstName")}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div style={(Object.assign({}), { textAlign: "center" })}>
                                    {/* <img src={passwordRecoveryGif} style={{width:"15%", height:"15%"}}></img> */}
                                    {Object.keys(userData).length > 1 ? (
                                        <div style={{ marginTop: "50px" }}>
                                            <img
                                                src={passwordRecoveryGif}
                                                style={{ width: "auto", height: "100px" }}
                                            ></img>
                                            <Typography variant="h6">
                                                確認コードを受け取る
                                            </Typography>
                                            <Typography variant="h7">
                                                HitRate から確認コードを
                                                <br />
                                                {registerData.email}に送信します
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "70px" }}>
                                            <Typography variant="h6">
                                                アカウントが見つかりません
                                            </Typography>
                                            <Typography variant="h7">
                                                入力された情報に一致する HitRate
                                                アカウントがありません。
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                                <div style={Object.assign({})}>
                                    <div>
                                        <Typography variant="h6">パスワードを変更</Typography>
                                        <Typography variant="h7">
                                            他のウェブサイトで使用していない安全なパスワードを新たに作成してください
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: "16px", marginBottom: "8px" }}>
                                        <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                            パスワード*
                                        </div>
                                        <FormControl variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel> */}
                                            <OutlinedInput
                                                fullWidth
                                                error={errorFlag}
                                                inputProps={{ style: { padding: "12px 15px" } }}
                                                type={values.showPassword ? "text" : "password"}
                                                value={registerData.password}
                                                onChange={registerDataChange("password")}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: "16px", marginBottom: "8px" }}>
                                        <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>確認*</div>
                                        <FormControl variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">確認</InputLabel> */}
                                            <OutlinedInput
                                                fullWidth
                                                error={errorFlag}
                                                inputProps={{ style: { padding: "12px 15px" } }}
                                                type={values.showPassword ? "text" : "password"}
                                                value={registerData.confirmPassword}
                                                onChange={registerDataChange("confirmPassword")}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {errorFlag ? (
                                            <FormHelperText
                                                style={{
                                                    textAlign: "center",
                                                    margin: "15px auto",
                                                    color: "red",
                                                }}
                                            >
                                                {passwordError}
                                            </FormHelperText>
                                        ) : (
                                            <FormHelperText
                                                style={{ textAlign: "center", margin: "15px auto" }}
                                            >
                                                半角英数字を組み合わせて8文字以上で入力してください
                                            </FormHelperText>
                                        )}
                                    </div>
                                </div>
                                <div style={Object.assign({})}>
                                    <div style={{ marginTop: "50px" }}>
                                        <Typography variant="h6">変更完了！</Typography>
                                        <Typography variant="h7">
                                            パスワードを変更しました。
                                            <br />
                                            ログイン画面に戻り変更したパスワードでログインしてください。
                                        </Typography>
                                    </div>
                                </div>
                                <div style={Object.assign({})}>
                                    <div style={{ marginTop: "50px" }}>
                                        <Typography variant="h6">エラー</Typography>
                                        <Typography variant="h7">
                                            エラーが発生しました。もう一度お試しください。
                                        </Typography>
                                    </div>
                                </div>
                            </SwipeableViews>
                        </div>
                    </PasswordStepper>
                </CardContent>
            </Card>
        </>
    )
}
