import Button from "@material-ui/core/Button"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import React from "react"

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '80%',
        margin: "5px auto",
        padding: "0px",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toLoginPage: {
        marginTop: "30px",
        position: "absolute",
        right: "0px",
        bottom: "5px",
        textTransform: "none",
    },
}))

function getSteps() {
    return ["アカウント確認", "メールアドレス認証", "パスワードの再設定"]
}

export default function PasswordStepper(props) {
    const {
        activeStep,
        activeDetailsStep,
        handleNext,
        handleBack,
        userData,
        confirmCode,
        buttonFlag,
    } = props
    const classes = useStyles()
    const steps = getSteps()

    const toSignPage = () => {
        navigate("/signIn")
    }

    //////////console.log({ userData, steps })

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel style={{ padding: "0px" }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <div>{props.children}</div>
                <div>
                    <Button
                        disabled={
                            activeDetailsStep === 0 ||
                            confirmCode > 99999 ||
                            activeDetailsStep === 5
                        }
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        戻る
                    </Button>
                    <Button
                        disabled={Object.keys(userData).length == 1 || buttonFlag}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        {activeStep === steps.length ? (
                            "SignIn画面に戻る"
                        ) : (
                            <>
                                {Object.keys(userData).length > 1 &&
                                activeDetailsStep == 2 &&
                                activeStep == 1
                                    ? "配信"
                                    : "次へ"}
                            </>
                        )}
                    </Button>
                    <div>
                        <Button
                            size="small"
                            color="primary"
                            onClick={toSignPage}
                            className={classes.toLoginPage}
                        >
                            {" "}
                            SignIn画面に戻る{" "}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
